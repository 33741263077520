






















import MachineModelForm from '@/components/machines/MachineModelForm.vue';
import { Collection } from '@/store/common/collections.ts';
import BaseMachineModelMixin from '@/components/machines/BaseMachineModelMixin.ts';
import Vue, { PropType, VueConstructor } from 'vue';
import { MachineModel } from '@/model/machine-model';
import { Service } from '@/model/service';
import { mapStores } from 'pinia';
import { useMachineModelsStore } from '@/store/machine-models-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseMachineModelMixin>
>).extend({
  components: { MachineModelForm },
  mixins: [BaseMachineModelMixin],
  props: {
    modelToEdit: {
      type: Object as PropType<MachineModel>,
      required: true
    }
  },
  computed: {
    ...mapStores(useMachineModelsStore)
  },
  methods: {
    async editMachineModel({
      machineModel,
      services
    }: {
      machineModel: MachineModel;
      services: Service[];
    }) {
      const servicesForApi = this.getCollectionForApi(services);

      machineModel = {
        ...machineModel,
        [Collection.SERVICES]: servicesForApi
      };

      await this.machineModelsStore.edit(machineModel);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.$bvModal.hide('machine-model-edit-' + this.modelToEdit.id);
      }
    }
  }
});
