





































import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import BaseMachineMixin from '@/components/machines/BaseMachineMixin.ts';
import MachineForm from '@/components/machines/MachineForm.vue';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { Machine } from '@/model/machine';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { MachinePanel, useUiStore } from '@/store/ui-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseMachineMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    BackButtonTitle,
    ConfirmModal,
    MachineForm,
    PlainButton
  },
  mixins: [BaseMachineMixin, ResourceConfigMixin],
  computed: {
    ...mapStores(useMachinesStore, useUiStore),
    currentMachine(): Machine {
      return this.machinesStore.currentEntity;
    }
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(MachinePanel.EDIT_MACHINE);
      this.machinesStore.unsetCurrentEntity();
    },
    openDeleteMachineModal() {
      ((this.$refs.deleteMachineModal as any) as IConfirmModal).openModal(
        `Veuillez confirmer la suppression de la machine <b>${this.currentMachine.name}</b> à l'aide du bouton "Supprimer" ci-dessous.`,
        {
          okTitle: 'Supprimer',
          okVariant: 'danger'
        }
      );
    },
    async onConfirmMachineDeletion() {
      const machineSuccessfullyDeleted = await this.machinesStore.delete(
        this.currentMachine
      );
      if (machineSuccessfullyDeleted) {
        this.machineModelsStore.removeMachineFromHisModelInStore(
          this.currentMachine
        );
        ((this.$refs.deleteMachineModal as any) as IConfirmModal).closeModal();
        this.closeCurrentPanel();
        this.uiStore.alertSuccess('Machine supprimée.');
      } else {
        ((this.$refs
          .deleteMachineModal as any) as IConfirmModal).sendingData = false;
      }
    },
    async editMachine({ machine }: { machine: Partial<Machine> }) {
      const machineEdited: Machine = await this.machinesStore.edit(machine);
      this.machineModelsStore.editMachineFromHisModelInStore(machineEdited);
    }
  }
});
