var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('form',{attrs:{"novalidate":""}},[_c('text-input',{attrs:{"value":_vm.machineModel.name,"errors":_vm.validationErrors,"name":"name","label":_vm.$t('label.name'),"help-text":_vm.$t('helpText.uniqueName'),"rules":"required"},on:{"onValueChange":function (value) {
          _vm.machineModel.name = value;
        }}}),(_vm.isFromLPittet)?_c('select-input',{attrs:{"options":_vm.categoriesOptions,"inline":false,"value":_vm.machineModel.category_id,"errors":_vm.validationErrors,"name":"category_id","label":"Catégorie associée"},on:{"onValueChange":function (value) {
          _vm.machineModel.category_id = value;
        }}}):_vm._e(),_c('div',{staticClass:"mb-3"},[_c('multi-select-input',{attrs:{"label":_vm.$t('machines.associatedService'),"track-by":"id","options":_vm.services,"default-value":_vm.associatedServices,"custom-label":function (ref) {
                var no = ref.no;
                var name = ref.name;

                return no + '. ' + name;
}},on:{"onValueChange":function (value) {
            _vm.associatedServices = value;
          }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }