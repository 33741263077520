


















































import TextInput from '@/components/shared/forms/TextInput.vue';
import { ValidationObserver } from 'vee-validate';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import BaseResourceFormMixin from '@/components/shared/BaseResourceFormMixin';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import Vue, { PropType, VueConstructor } from 'vue';
import { MachineModel } from '@/model/machine-model';
import { mapStores } from 'pinia';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { useServicesStore } from '@/store/services-store';
import { useCategoriesStore } from '@/store/categories-store';
import {
  MachinePanel,
  MACHINES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useUsersStore } from '@/store/users-store';
import { Service } from '@/model/service';
import { InputOption } from '@/model/input-option';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceFormMixin>
>).extend({
  components: {
    TextInput,
    ValidationObserver,
    MultiSelectInput,
    SelectInput
  },
  mixins: [BaseResourceFormMixin],
  props: {
    machineModelToEdit: {
      type: Object as PropType<MachineModel>,
      default: () => {}
    }
  },
  data() {
    return {
      machineModel: {
        name: null as null | string
      } as Partial<MachineModel>
    };
  },
  computed: {
    ...mapStores(
      useMachineModelsStore,
      useServicesStore,
      useCategoriesStore,
      useUsersStore,
      useUiStore
    ),
    validationErrors(): any {
      return this.machineModelsStore.validationErrors;
    },
    machineModels(): MachineModel[] {
      return this.machineModelsStore.entities;
    },
    services(): Service[] {
      return this.servicesStore.entities;
    },
    categoriesOptions(): InputOption[] {
      return this.categoriesStore.getAsSelectOptions;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  watch: {
    machineModelToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.machineModel = {
        id: this.machineModelToEdit ? this.machineModelToEdit.id : null,
        name: this.machineModelToEdit ? this.machineModelToEdit.name : null,
        category_id: this.machineModelToEdit
          ? this.machineModelToEdit.category_id
          : null
      };
      this.fillExistingAssociatedData(this.machineModelToEdit);
    },
    addService() {
      this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.ADD_SERVICE);
    },
    async submit() {
      const isValid = await (this.$refs.observer as any).validate();
      if (isValid) {
        this.$emit('onFormValidated', {
          machineModel: this.machineModel,
          services: this.associatedServices
        });
      }
    }
  }
});
