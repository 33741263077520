



















































import MachinesList from '@/components/machines/MachinesList.vue';
import AddMachine from '@/components/machines/AddMachine.vue';
import EditMachine from '@/components/machines/EditMachine.vue';
import AddService from '@/components/services/AddService.vue';
import MachineModelAddModal from '@/components/machines/MachineModelAddModal.vue';
import Vue from 'vue';
import {
  MachinePanel,
  MACHINES_PANELS_LAYOUT,
  Panel,
  useUiStore
} from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { Machine } from '@/model/machine';
import { useUsersStore } from '@/store/users-store';
import { useMachineModelsStore } from '@/store/machine-models-store';
import PlainButton from '@/components/shared/PlainButton.vue';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';

export default Vue.extend({
  components: {
    MachinesList,
    AddMachine,
    EditMachine,
    AddService,
    MachineModelAddModal,
    PlainButton,
    ButtonGroup
  },
  data() {
    return {
      MACHINE_PANEL: MachinePanel
    };
  },
  computed: {
    ...mapStores(
      useUiStore,
      useMachinesStore,
      useUsersStore,
      useMachineModelsStore
    ),
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentMachine(): Machine {
      return this.machinesStore.currentEntity;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    currentPanel(): string {
      return this.uiStore.currentPanel;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    }
  },
  created() {
    this.machineModelsStore.findAll(this.vendorId, true, false);
    this.machinesStore.findAll(this.vendorId, true, false);
    this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.MACHINES_LIST);
    if (this.currentMachine && this.currentMachine.id) {
      this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.EDIT_MACHINE);
    }
  },
  methods: {
    isPanelOpened(panel: Panel): boolean {
      return this.uiStore.isPanelOpened(panel);
    },
    addMachine() {
      this.machinesStore.unsetCurrentEntity();
      this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.ADD_MACHINE);
      this.machineModelsStore.setMachineModelIdToAdd(null);
    },
    addMachineModel() {
      this.$bvModal.show('machine-model-add');
    }
  }
});
