var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFetching)?_c('loading-indicator'):_vm._e(),(!_vm.isFetching)?_c('div',[_c('search',{attrs:{"done-typing-interval":0,"default-value":_vm.searchTerms,"placeholder":_vm.$t('machines.search.placeholder'),"icon":"fas fa-filter"},on:{"onSearch":function($event){_vm.searchTerms = $event}}}),(_vm.machines.length === 0 && _vm.machineModels.length === 0)?_c('empty-dataset',{attrs:{"text":_vm.$t('machines.emptyDataset'),"icon":"briefcase"}}):_vm._e(),_c('confirm-modal',{ref:"deleteModelModal",on:{"confirm":_vm.onConfirmModelDeletion}}),_vm._l((_vm.machineModels),function(model){return _c('div',{key:model.id,staticClass:"pb-1 mb-3 bg-light p-3 border-radius"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-sm text-uppercase font-weight-bolder mr-2"},[_vm._v(" "+_vm._s(model.name)+" ")]),_c('div',[(model.machines.length === 0)?_c('i',{staticClass:"fas fa-trash-alt pointer mr-3 text-medium",on:{"click":function($event){return _vm.openDeleteModelModal(model)}}}):_vm._e(),_c('i',{staticClass:"fas fa-pen pointer text-medium mr-3",on:{"click":function($event){return _vm.editModel(model)}}}),_c('machine-model-edit-modal',{attrs:{"model-to-edit":model}}),_c('i',{staticClass:"fas fa-plus pointer text-primary",on:{"click":function($event){return _vm.addMachine(model)}}})],1)]),_vm._l((_vm.getMachinesFiltered(model.id)),function(machine){return _c('div',{key:machine.id,staticClass:"panel-item panel-item-subcategory",class:{
          active: _vm.currentMachine && _vm.currentMachine.id === machine.id
        },on:{"click":function($event){return _vm.editMachine(machine)}}},[_c('div',{class:{
            'text-danger': _vm.hasConfigurationErrors(
              _vm.COLLECTION.MACHINES,
              machine.id
            )
          }},[_vm._v(" "+_vm._s(machine.name)+" "),(_vm.hasConfigurationErrors(_vm.COLLECTION.MACHINES, machine.id))?_c('i',{staticClass:"fas fa-exclamation-triangle ml-2"}):_vm._e()]),_vm._m(0,true)])})],2)})],2):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-item-right"},[_c('i',{staticClass:"fas fa-pen ml-2 text-medium"})])}]

export { render, staticRenderFns }