import ResourceMixin from '@/mixins/ResourceMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { Service } from '@/model/service';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  computed: {
    ...mapStores(useServicesStore, useMachineModelsStore),
    allServices(): Service[] {
      return this.servicesStore.entities;
    },
    sendingData(): boolean {
      return this.machineModelsStore.saving;
    },
    validationErrors(): any {
      return this.machineModelsStore.validationErrors;
    }
  }
});
