














































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import Search from '@/components/shared/Search.vue';
import MachineModelEditModal from '@/components/machines/MachineModelEditModal.vue';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import find from 'lodash/find';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useMachineModelsStore } from '@/store/machine-models-store';
import { useMachinesStore } from '@/store/machines-store';
import { MachineModel } from '@/model/machine-model';
import { Machine } from '@/model/machine';
import {
  AlertType,
  MachinePanel,
  MACHINES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    EmptyDataset,
    Search,
    MachineModelEditModal,
    ConfirmModal
  },
  mixins: [ResourceConfigMixin],
  data: () => {
    return {
      searchTerms: ''
    };
  },
  computed: {
    ...mapStores(useMachineModelsStore, useMachinesStore, useUiStore),
    isFetching(): boolean {
      return this.machineModelsStore.fetching;
    },
    machineModels(): MachineModel[] {
      return this.machineModelsStore.entities;
    },
    machines(): Machine[] {
      return this.machinesStore.entities;
    },
    currentMachine(): Machine {
      return this.machinesStore.currentEntity;
    }
  },
  methods: {
    addMachine(model: MachineModel) {
      this.machinesStore.unsetCurrentEntity();
      this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.ADD_MACHINE);
      this.machineModelsStore.setMachineModelIdToAdd(model.id);
    },
    editMachine(machine: Machine) {
      this.uiStore.openPanel(MACHINES_PANELS_LAYOUT, MachinePanel.EDIT_MACHINE);
      this.machinesStore.setCurrentEntity(machine);
    },
    editModel(model: MachineModel) {
      this.$bvModal.show('machine-model-edit-' + model.id);
    },
    openDeleteModelModal(model: MachineModel) {
      ((this.$refs.deleteModelModal as any) as IConfirmModal).openModal(
        `Veuillez confirmer la suppression du modèle <b>${model.name}</b> à l'aide du bouton "Supprimer" ci-dessous.`,
        {
          okTitle: 'Supprimer',
          okVariant: 'danger'
        },
        model
      );
    },
    async onConfirmModelDeletion(model: MachineModel) {
      const modelSuccessfullyDeleted = await this.machineModelsStore.delete(
        model
      );
      ((this.$refs
        .deleteModelModal as any) as IConfirmModal).sendingData = false;
      if (modelSuccessfullyDeleted) {
        ((this.$refs.deleteModelModal as any) as IConfirmModal).closeModal();
        this.uiStore.alert(this.$t('toast.changesSaved'), AlertType.SUCCESS);
      }
    },
    getMachinesFiltered(modelId: number) {
      return find(this.machineModels, { id: modelId }).machines.filter(
        machine => {
          return machine.name
            .toUpperCase()
            .includes(this.searchTerms.toUpperCase());
        }
      );
    }
  }
});
